// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import i18n from './library/i18n'                         // 다국어지원
import GlobalComponents from './library/globalComponents'
import moment from 'moment'
import numeral from 'numeral'
Vue.use(GlobalComponents)

import { Settings } from 'luxon'
//set to display dates for English language
Settings.defaultLocale = 'en'

import store from './store'  // Vue 스토어


Vue.prototype.$EventBus = new Vue()

Vue.config.productionTip = false
// window.vue2PanelDebug = true; // Vue Sildeout Panel


Vue.prototype.alert = (param) => {
  
  app.$modal.show('alert',{
    ...param
  })
}

Vue.prototype.iText = (dec,id,value) => {
  let text = ''
  if(id){
    text  = app.$i18n.t(id)
  }else{
    text  = app.$i18n.t(dec)
  }
  return text
}


Vue.prototype.confirm = (param) => {
  app.$modal.show('alertConfirm',{
    ...param
  })
}
Vue.prototype.valCheck = (val) => {
  if(val == '' || val == null){
    return true
  }else{
    return false
  }
}

Vue.prototype. loadingShow = () => {
  app.$EventBus.$emit('fullLoading', true)
}
Vue.prototype. loadingHide = () => {
  app.$EventBus.$emit('fullLoading', false)
}
Vue.prototype.historyBack = (val) => {
  app.$router.go(-1)
}
Vue.prototype.numberFormat = (data,dd) => {
  if (!data) return 0
  let decimal = '0,0'
  let num = String(data).replace(/\,/g,'')
  num = Number(num)
  if(dd){
    num  = Math.floor(data * 10000) / 10000 
    
    if(dd == 0 ){
      num  = Math.floor(num)
      decimal = '0,0'
    }else{
      decimal = '0,0.00'
    }
    return numeral(num).format(decimal) ;
  }else{
    num  = Math.floor(data * 10000) / 10000 
    num = +parseFloat(num).toFixed(4)
    let dd = numeral(num).format("0,0") ;
    return dd
  }
  
}
Vue.prototype.numberFilter = (val) => {
  if(val == '' || val == null){
    return 0
  }else{
    let num = String(val).replace(/,/gi, ""); 
    return Number(num)
  }
}



Vue.prototype.openModal = (id) => {
  app.$modal.show(id,{ scrollable: true })
}

Vue.prototype.closeModal = (id) => {
  app.$modal.hide(id)
}
Vue.prototype.newDate = (event,) => {
  if(!event){
    return event
  }
  let date = new Date(event)
  var timeOffset = moment().utcOffset(); // this gives the UTC/GMT time
  
  let dd = timeOffset
  if(timeOffset < 0 ){
    dd = timeOffset * -1  
  }else{
    dd = (timeOffset * -1  ) + 1
  }
  
  let data = moment(date).add(dd, 'minutes');
 
  return new Date(data)
}
Vue.prototype.fixDate = (event,formatVal) => {
  
  if(!event || event == '' || event == null) {
    return null;
  }
  var timeOffset = moment().utcOffset(); // this gives the UTC/GMT time
  
  let dd = 0//timeOffset * -1
  if(dd < 0 ){
    dd = dd + 1
  }
  let data = moment(event).add(dd, 'minutes');

  if(formatVal){
    return moment(data).format(formatVal)
  }else{
    return moment(data).format('YYYY-MM-DD')
  }
  
}
Vue.prototype.masking = (str, chr, remainPosition, width) => {
  if(!str){
    return ''
  }
  str = str.trim();
 
  var temp = "";
  
  temp = str.substring(0, str.length - remainPosition - width );
  
  for ( var i = 0; i < width; i++ ) {
   temp = temp + chr;
  }
         temp += str.charAt(str.length-1);
  return temp;
}
Vue.prototype.licensorCheck = (session_userInfo) => {
  
  if(!session_userInfo.licensor){
    return true
  }
  if(!session_userInfo.licensor.bankaccount){
    return true
  }
  if(!session_userInfo.licensor.bankaccount.payment_method){
    return true
  }
  return (session_userInfo.licensor.bankaccount.payment_method !="paypal") && !( session_userInfo.licensor.bankaccount.account_number )
}
const app = new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
export default app 

